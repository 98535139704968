import { type User, UserManager, type UserManagerSettings } from "oidc-client-ts";
import { BehaviorSubject } from "rxjs";

let _settings: UserManagerSettings;
let _userMngr: UserManager;
let _subject: BehaviorSubject<User | undefined | null>;
export const updateSettings = (settings: UserManagerSettings) => {
	_settings = settings;
	_userMngr = new UserManager(settings);

	_subject = new BehaviorSubject<User | undefined | null>(undefined);
	console.log("Subcribe to user session data.");
	_userMngr.events.addUserUnloaded(() => {
		console.log("user unloaded");
		_subject.next(null);
	});
	_userMngr.events.addUserLoaded((user: User) => {
		console.log("user loaded", user);
		_subject.next(user);
	});
	_userMngr.events.addUserSessionChanged(() => {
		console.log("Session Changed");
	});
	_userMngr.events.addSilentRenewError((e) => {
		console.log("SilentRenewError", e);
		_subject.next(null);
	})
};

export const signinCallback = async (loc = window.location.href) => {
	console.log("[authentication] signinCallback trigger", loc);
	try {
		const user = await userManager().signinCallback(loc);
		console.log("[authentication] signinCallback Session Response", user);
		if (user?.state && window.location.href !== user.state) {
			window.location.href = user.state as string;
		}
	} catch (error) {
		console.warn("[authentication] signinCallback", error);
		_subject.next(null);
	}
};

export const userManager = () => _userMngr;

export const getUser$ = () => {
	return _subject;
};
