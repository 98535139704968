import { signinCallback } from "./manager.js";

export class OidcClient extends HTMLElement {
	static readonly TagName = "oidc-client-callback";
	static register() {
		customElements.define(OidcClient.TagName, OidcClient);
	}
	static createElement(params = undefined) {
		document.createElement(OidcClient.TagName, params);
	}

	connectedCallback() {
		this.innerHTML = "<div class='loading'>oidc loading...</div>";
		console.log(
			"[%s] added to page.",
			OidcClient.TagName,
			window.location.href,
		);
		signinCallback();
	}

	disconnectedCallback() {
		console.log("[%s] removed from page.", OidcClient.TagName);
	}
}
