import type { SigninSilentArgs } from "oidc-client-ts";
import { userManager, getUser$ } from "./manager.js";

export const login = (redirect_uri: string | undefined = undefined) => {
	return userManager().signinRedirect({ state: redirect_uri });
};

export const logout = (redirect_uri: string) => {
	return userManager().signoutRedirect({ post_logout_redirect_uri: redirect_uri });
};

export const userInfo = () => {
	return userManager().getUser();
};

export const userInfo$ = () => {
	return getUser$();
};

export const signinSilent = (args?: SigninSilentArgs) => {
	return userManager().signinSilent(args);
}
