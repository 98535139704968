import { type UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import {
	getUser$,
	signinCallback as signinCallbackImpl,
	updateSettings,
	userManager,
} from "./manager.js";
import { OidcClient } from "./oidc-client.js";

export * from "./api.js";

export const signinCallback = async (loc = window.location.href) => {
	return signinCallbackImpl(loc);
};

type OIDCConfig = {
	client_id: string;
	scope: string;
	authority: string;
	redirect_uri: string;
};

export const bootstrap = async (config: {
	authority: string;
	oidc?: OIDCConfig;
}) => {
	console.log("[authentication] bootstrap...", config);

	OidcClient.register();

	const url = `${window.location.origin}/auth`;
	const clientId = config.oidc?.client_id || "js.tokenmanager";
	const settings: UserManagerSettings = {
		authority: config.authority || `https://auth.${window.location.hostname}`,
		client_id: clientId,
		scope: "openid email profile",
		redirect_uri: `${url}/callback`,
		silent_redirect_uri: `${url}/silent-refresh`,
		post_logout_redirect_uri: window.location.origin,
		loadUserInfo: true,
		automaticSilentRenew: false,
		userStore: new WebStorageStateStore({
			prefix: clientId,
			store: window.localStorage,
		}),
		monitorSession: true,
		filterProtocolClaims: true,
		...(config.oidc || {}),
	};
	updateSettings(settings);
};

export const mount = async () => {
	console.log("[authentication] mounting...");
	try {
		const user = await userManager().getUser();
		console.log("[authentication] mount User", user);
		getUser$().next(user);
	} catch (e) {
		console.log("[authentication] mount", e);
	}
};
